// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-poppins"
import "typeface-pt-sans"
import "typeface-mukta"
// normalize CSS across browsers
// import "./src/normalize.css"
// custom CSS styles
// import "./src/style.css"

import "./src/styles/global.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"

const GlobalStyles = createGlobalStyle`
* { 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
}

body, html {
    font-family: ${(props) => props.theme.fonts.main};
    height: 100%;
    background-color: ${(props) => props.theme.colors.background};
}
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    {element}
  </ThemeProvider>
)
