export default {
  fonts: {
    main: "Montserrat, sans-serrif",
    code: "Roboto, sans-serif",
    menu: "Mukta, sans-serif",
    // header1: "Francois One, sans-serif",
    // header1: "Futura, sans-serif",
    header1: "Poppins, sans-serif",
    header2: "Poppins, sans-serif",
    header3: "Poppins, sans-serif",
    header4: "PT Sans, sans-serif",
    button: "Poppins, sans-serif",
    body: "Mukta, sans-serif",
    weight300: "300",
    weight400: "400",
    weight600: "600",
    weight700: "700",
  },
  spacings: {
    xxSmall: "0.25rem",
    xSmall: ".5rem",
    mSmall: "0.8rem",
    lSmall: "1rem",
    // small: "1.8rem",
    small: "1.3rem",
    medium: "1.8rem",
    xMedium: "2rem",
    xxMedium: "2.5rem",
    large: "3rem",
    xLarge: "4rem",
    xxLarge: "6rem",
  },
  colors: {
    light1: "hsl(0, 0%, 97%)",
    light2: "hsl(0, 0%, 99%)",
    light3: "hsl(0, 0%, 90%)",
    light4: "hsl(0, 0%, 96%)",
    dark1: "hsl(227, 2%, 12%)",
    dark2: "hsl(227, 2%, 26%)",
    dark3: "hsl(227, 2%, 64%)",
    background: "hsla(0, 11%, 99%,1)",
    basePrimaryBlue: "#3FA9F5",
    primaryBlueNormal: "hsla(205, 90%, 60%, 1)",
    primaryBlueLighter: "hsla(205,90%,70%,1)",
    primaryBlueDarker: "hsla(205,90%,50%,1)",
    baseSecondaryBlue: "#0F93F2",
    secondaryBlueNormal: "hsla(205, 90%, 50%, 1)",
    secondaryBlueLighter: "hsla(205, 90%, 62.5%, 1)",
    secondaryBlueDarker: "hsla(205, 90%, 37.5%, 1)",
    basePrimaryOrange: "#F58B3F",
    primaryOrangeNormal: "hsla(25, 90%, 60%, 1)",
    primaryOrangeLighter: "hsla(25, 90%, 70%, 1)",
    primaryOrangeDarker: "hsla(25, 90%, 50%, 1)",
    baseSecondaryOrange: "#F26E0F",
    secondaryOrangeNormal: "hsla(25, 90%, 50%, 1)",
    secondaryOrangeLighter: "hsla(25, 90%, 62.5%, 1)",
    secondaryOrangeDarker: "hsla(25, 90%, 37.5%, 1)",
    black: "hsla(0, 0%, 18%, 1)",
    white: "hsla(360, 100%, 100%, 1)",
    basePrimaryRed: "#5c0c10",
    primaryRedNormal: "hsla(357, 77%, 20%, 1)",
    primaryRedLighter: "hsla(357, 77%. 40%, 1)",
    primaryRedDarker: "hsla(357, 77%, 10%, 1)",
    basePrimaryPink: "#ff38b6",
    primaryPinkNormal: "hsla(322, 100%, 61%, 1)",
    primaryLightGray: "hsla(0, 0%, 96%)",
  },
  breakpoints: {
    mobileREM: "only screen and (max-width: 50rem)",
    tabletREM: "only screen and (max-width: 65rem)",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "0px 5px 20px rgba(30,30,31, 0.05)",
    shadow2: "3px 3px 15px grey",
  },
}

// Lighter = L + ((100 - L) * 0.25)
// Darker =  L - ((100 - L) * 0.25)
